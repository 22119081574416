class arrayHelper {

    // helper to find duplicate values in an array of objects
    static findDuplicates(array, key) {
        const seen = {};
        const duplicates = [];

        array.forEach(item => {
            const value = item[key].toLowerCase();
            if (seen[value]) {
                duplicates.push(item);
            } else {
                seen[value] = true;
            }
        });

        return duplicates;
    }

    static findMissingValues(array, key) {
        return array.filter(item => {
            return !item[key] || item[key] === null || item[key]?.length < 1
        });
    }
}
export default arrayHelper;