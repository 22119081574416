// src/utils/customFieldValidator.js
import arrayHelper from './arrayHelper.js'

export default {
    /**
     * Find fields with missing values in their options
     * @param {Array} customFields - Array of all custom fields
     * @param {Array} parentFields - Array of parent fields that have children linked to them
     * @returns {Array} Fields with missing options
     */
    findMissingValues(customFields, parentFields) {
        const childFields = customFields.filter(field => field.linked_field)
        const fieldsToCheckForOptions = childFields.concat(parentFields)
        return arrayHelper.findMissingValues(fieldsToCheckForOptions, 'options')
    },

    /**
     * Find child fields that don't have all parent options assigned
     * @param {Array} customFields - Array of all custom fields
     * @returns {Array} Child fields without all options assigned
     */
    findChildrenWithoutAllOptionsAssigned(customFields) {
        const childFields = customFields.filter(field => field.linked_field)
        return childFields?.filter(child => {
            const parent = customFields.find(field => field.field_id === child.linked_field)
            if (!parent || !parent.options) return false

            const parentOptions = parent.options.map(option => option.value)
            const childLinkedValues = child.options?.map(option => option.linked_value) || []

            return !parentOptions.every(option => childLinkedValues.includes(option))
        })
    },

    /**
     * Find fields with invalid linked values
     * @param {Array} customFields - Array of all custom fields
     * @returns {Array} Fields with invalid linked values
     */
    findInvalidLinkedValues(customFields) {
        return customFields.map(customField => {
            if (!customField.linked_field) return

            const linkedField = customFields.find(field => field.field_id === customField.linked_field)
            const validValues = linkedField?.options?.map(option => option.value)

            if (!validValues) return

            const hasInvalidValues = customField.options.filter(option => {
                return validValues.includes(option.linked_value) === false
            });

            if(hasInvalidValues.length > 0) return customField
        }).filter(Boolean);
    }
}